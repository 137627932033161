<template>
  <div class="formline bgc xpad">
    <div class="markadata ">
      <div :class="{ notvalid: problem }" class="forminput">
        <div class="formtriple marka">
          <h4 class="center">Marka Ekleme</h4>

          <div class="flex">
            <div class="tripletwo">
              <div class="formlabel">
                <label>Marka (ya da İşletme) Adı</label>
  
                <tool-tip class="right"
                  >Yöneteceğiniz markanın tam adını (Marka Tescil Belgesi'ndeki
                  gibi) giriniz.</tool-tip>
              </div>
              <!-- <div class="desc">Yöneteceğiniz markanın tam adını (Marka Tescil Belgesi'ndeki gibi) giriniz.</div> -->
              <input type="text" v-model="brand.name" @input="problem = ''" />
              <div class="hint">{{ problem }}</div>
            </div>
  
            <input-file-new
              :showToolip="master"
              docType="MARKA_TESCIL_BELGESI"
              v-model="file"
            ></input-file-new>
          </div>

        </div>
      </div>

      <!-- İLETİ YÖNETİMİ -->
      <div class="formtriple" v-show="false">
        <h4 class="center">İleti Yönetimi</h4>

        <div class="tripleone" v-show="false">
          <div class="formlabel">
            <label>Mesajla ulaşılan alıcı sayısı</label>
            <!-- <tool-tip> Sadece mesaj yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>

          <input type="text" min="0" step="100" v-model="brand.props.text" />
          <div class="hint"></div>
        </div>

        <div class="tripletwo">
          <div class="formlabel">
            <label>Mesaj gönderdirirken kullandığınız kısa isimler</label>
            <tool-tip  class="right"
              >Mesaj iletilerinizde "gönderen (outbox name)" alanında görünen
              isimleri yazınız.</tool-tip>
          </div>
          <chip
            v-model="brand.props.alfaNumerics"
            validation="SHORT"
            maxLen="11"
          ></chip>
        </div>

        <div class="tripleone" v-show="false">
          <div class="formlabel">
            <label>Aramayla ulaşılan alıcı sayısı</label>
            <!-- <tool-tip>Sadece arama yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>
          <input type="text" min="0" step="100" v-model="brand.props.voice" />
          <div class="hint"></div>
        </div>
        <div class="tripletwo">
          <div class="formlabel">
            <label>Arama yaparken kullandığınız telefon numaraları</label>
            <tool-tip class="right"
              >Arama iletilerinizde "arayan" alanında görünen telefon
              numaralarınızı yazınız.</tool-tip
            >
          </div>

          <chip
            v-model="brand.props.phoneNumbers"
            validation="PHONE"
            maxLen="11"
          ></chip>
        </div>

        <div class="tripleone" v-show="false">
          <div class="formlabel">
            <label>E-postayla ulaşılan alıcı sayısı</label>
            <!-- <tool-tip> Sadece e-posta yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>

          <input type="text" min="0" step="1000" v-model="brand.props.mail" />
          <div class="hint"></div>
        </div>

        <div class="tripletwo">
          <div class="formlabel">
            <label>E-posta gönderirken kullandığınız e-posta adresleri</label>
            <tool-tip class="right"
              >E-posta iletilerinizde "gönderen/kimden/(from)" alanında açık
              şekilde yazan e-posta adreslerini yazınız.</tool-tip
            >
          </div>
          <chip
            v-model="brand.props.emailAddresses"
            validation="EMAIL"
            maxLen="100"
          ></chip>
        </div>
      </div>

      <!-- <h4>ANKETİMİZE HOŞ GELDİNİZ!!!</h4> -->
      <div class="formtriple" v-show="false">
        <h4 class="center">İstatistiksel Bilgiler</h4>
        <div class="tripleone">
          <div class="formlabel">
            <label>Geçtiğimiz yıl kaç mesaj gönderimi yaptınız?</label>
            <!-- <tool-tip> Sadece e-posta yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>
          <input
            type="text"
            min="0"
            step="1000"
            v-model="brand.props.prevSendMessages"
          />
        </div>

        <div class="tripleone">
          <div class="formlabel">
            <label
              >Geçtiğimiz yıl izinli listenize kaç cep telefonu numarası
              eklediniz?</label
            >
            <!-- <tool-tip> Sadece e-posta yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>
          <input
            type="text"
            min="0"
            step="1000"
            v-model="brand.props.prevNewPhoneNumbers"
          />
        </div>

        <div class="tripleone">
          <div class="formlabel">
            <label
              >Geçtiğimiz yıl kaç cep telefonu numarasından ret bildirimi
              aldınız?</label
            >
            <!-- <tool-tip> Sadece e-posta yoluyla ulaştığınız müşteri sayınızı ifade eder.</tool-tip> -->
          </div>
          <input
            type="text"
            min="0"
            step="1000"
            v-model="brand.props.prevRejectPhoneNumbers"
          />
        </div>
      </div>

      <div class="btns">
        <div class="btn half center third" @click="$emit('close')">
          <a>İptal</a>
        </div>
        <div class="btn half center second" @click="ADD_NEW_MARKA">
          <a>Kaydet</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Chip from "./Chip";
import InputFileNew from "./InputFileNew";
import { onlyNumberWithMaxLen } from "../../../../misc/helpers";

export default {
  name: "MarkaInputNew",

  components: {
    Chip,
    InputFileNew
  },

  props: {
    master: {
      type: Boolean,
      required: true
    },
    problem: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      file: null,
      brand: {
        id: null,
        name: "",
        fileId: null,
        master: false,
        props: {
          order : 0,
          voice: "1",
          text: "1",
          mail: "1",
          phoneNumbers: [],
          alfaNumerics: [],
          emailAddresses: [],
          prevSendMessages: "1",
          prevNewPhoneNumbers: "1",
          prevRejectPhoneNumbers: "1"
        },
        ui: {
          file: null,
          deleted: false,
          input: false,
          deleteAnim: false
        }
      }
    };
  },

  watch: {
    file(n) {
      this.$env.CONSOLE.log("File to upload:", n);
    },

    brand: {
      deep: true,
      handler(n) {
        n.props.text = onlyNumberWithMaxLen(n.props.text, 9);
        n.props.voice = onlyNumberWithMaxLen(n.props.voice, 9);
        n.props.mail = onlyNumberWithMaxLen(n.props.mail, 9);
        n.props.prevSendMessages = onlyNumberWithMaxLen(
          n.props.prevSendMessages,
          9
        );
        n.props.prevNewPhoneNumbers = onlyNumberWithMaxLen(
          n.props.prevNewPhoneNumbers,
          9
        );
        n.props.prevRejectPhoneNumbers = onlyNumberWithMaxLen(
          n.props.prevRejectPhoneNumbers,
          9
        );
        this.$emit("input", n);
      }
    }
  },

  computed: {
    izin_total() {
      return (
        (parseInt(this.brand.props.voice) || 0) +
        (parseInt(this.brand.props.text) || 0) +
        (parseInt(this.brand.props.mail) || 0)
      );
    }
  },

  methods: {
    ADD_NEW_MARKA() {
      let entry = this.brand;

      if (entry.name.length < 2) {
        this.Alert("Lütfen geçerli bir marka ismi giriniz.");
        return;
      }

      if (this.izin_total < 1) {
        this.Alert("Lütfen en az bir alıcı adedi giriniz.");
        return;
      }

      if (!this.file && !this.master) {
        this.Alert("Lütfen ilgili markanın tescil dosyasını yükleyiniz.");
        return;
      }

      let brandName = this.brand.name;

      entry.master = this.master;

      if (this.file) entry.ui.file = this.file;

      this.$emit("add", { ...entry });
    }
  }
};
</script>

<style scoped>

.flex {display: flex; width: 100%;}

.btns {
  display: flex;
}

.bgc {
  background: #fcfcfc;
  position: relative;
}

.formtriple .inputfile + label {
  height: unset !important;
  line-height: 5vh;
  padding: 0;
  margin-top: 0.5vh;
}

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
}
.cancel img {
  display: flex;
  justify-content: center;
  width: auto;
  background: #ebebeb;
  padding: 0.1vw;
  cursor: pointer;
  float: right;
  width: 1.4vw !important;
  height: 1.4vw !important;
  border-radius: 0.5vw;
}

.cancel img:hover {
  background: #dddddd;
}

.formtriple {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 1vw;
  margin-bottom: 2vh;
  border: 1px solid #eee;
  border-radius: 0.5vw;
  align-items: flex-start;
  flex-wrap: wrap;
}

.formtriple .tripleone {
  display: flex;
  align-items: flex-end;    
  width: 34%;
  margin-top: 2vh;
}



.formtriple .tripletwo {
  width: 66%;
  margin-top: 2vh;
}
.formtriple .mark {
  width: 100%;
}

.formtriple h4 {
  width: 100%;
}

.formtriple .mark {
  width: 100%;
}

.formtriple.marka {
  align-items: flex-end;
}

.formlabel {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.izinsection label {
  color: #55565e;
}

.markadata {
  position: relative;
  width: 100%;
  margin: 1vh auto;
  -ms-flex-align: center;
}
.markadata label {
  font-size: 1rem;
}

/*  on big screens ********************************************************************************************/

@media only screen and (min-height: 1000px) {
}

/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {
  .mobileone {
    width: 100% !important;
  }
  .markadata {
    width: 100%;
    margin: 0 auto;
  }
  .formtriple {
    padding: 2vw;
    border-radius: 2vw;
  }
  .formtriple .tripleone {
    padding-right: 2vw;
  }
  .cancel a {
    width: 4.4vw !important;
    height: 4.4vw !important;
    border-radius: 1vw;
  }
}

/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .markadata {
    width: 100%;
    margin: 0 auto;
  }
  .formtriple {
    padding: 2vw;
    border-radius: 2vw;
    flex-wrap: wrap;
  }
  .formtriple .tripleone {
    width: 100%;
    padding-right: 0;
  }
  .formtriple .tripletwo {
    width: 100%;
  }
  .cancel a {
    width: 7vw !important;
    height: 7vw !important;
    border-radius: 1vw;
  }
  .formtriple {
    margin-bottom: 6vh;
  }

  label img {
    width: 7%;
    height: 42%;
    border-radius: 0;
    position: absolute;
    top: -40px;
    margin-top: 2vh;
  }

  .mobileone {
    width: 100% !important;
  }
  .total {
    border-radius: 2vw;
    padding: 3vw;
  }

  .formfull .nedir {
    min-width: 37vw;
    padding: 1vw;
  }
  .formfull .nedir::after {
    width: 4vw;
    height: 4vw;
    margin-left: 2vw;
  }

  .branddel a {
    padding: 1vw;
    margin-bottom: -4vh;
  }

  input[type="radio"] + label {
    border-radius: 2vw;
    width: 50%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
